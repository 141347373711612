<template>
    <div class="w-full p-2 flex flex-col justify-center items-center text-center">
        <component-newsletter-signup-mini class="md:block hidden"/>
        <div>
            <p><webmessage wsm="wsm_footerCompanyInfo"></webmessage></p>
        </div>
    </div>
</template>

<script lang="ts">

export default defineNuxtComponent({
    name: "componentFooter",
})
</script>

<style lang="scss">
.router-link-active {
    @apply text-[#e3cd9a];
}
</style>